import 'react-app-polyfill/ie11';

import React, { useEffect, useState } from 'react';

import FormService from '../../service/formService';
import FormUtils from '../../utils/formUtils';
import Layout from '../layout';
import PageLoader from '../subComponents/pageLoader';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from './step8';

function SupplementNavigation() {
  const [state, setState] = useState({
    enrolled_medicare_part_b: '',
    covered_by_other: '',
    other_insurance_type: '',
    plan_type: '',
    insurance_provider: '',
    month_enrolled: '',
    year_enrolled: '',
    other_plan_type: '',

    spouse_enrolled_medicare_part_b: '',
    spouse_covered_by_other: '',
    spouse_other_insurance_type: '',
    spouse_plan_type: '',
    spouse_other_plan_type: '',
    spouse_insurance_provider: '',
    spouse_month_enrolled: '',
    spouse_year_enrolled: '',

    mail_information: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    additional_phone: '',
    additional_info: '',

    selectedComponent: undefined,
    lead_id: '',
    call_in_num: '(888) 379-9437',
  });

  const [loading, setLoading] = useState(false);
  const [hasSpouse, setHasSpouse] = useState('');
  const [params, setParams] = useState({});

  const setStep6Details = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
      selectedComponent: hasSpouse === 'yes' ? 'Step7' : 'Step8',
    }));
    FormUtils.scrollToTop();
  };

  const setStep7Details = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
      selectedComponent: 'Step8',
    }));
    FormUtils.scrollToTop();
  };

  const setStep8Details = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
      selectedComponent: 'Submit',
    }));
    FormUtils.scrollToTop();
    window.onbeforeunload = null;
  };

  const renderInnerComponent = () => {
    if (loading || state.selectedComponent === undefined) return <PageLoader />;
    else
      switch (state.selectedComponent) {
        case 'Step6':
          return <Step6 onSubmit={setStep6Details} data={state} />;
        case 'Step7':
          return <Step7 onSubmit={setStep7Details} data={state} />;
        case 'Step8':
          return <Step8 onSubmit={setStep8Details} data={state} />;
        case 'Loading':
          return <PageLoader />;
        default:
          return <Step6 onSubmit={setStep6Details} data={state} />;
      }
  };

  const handlePrimaryOther = () => {
    if (state.plan_type === 'Other') {
      return state.other_plan_type;
    } else {
      return state.plan_type;
    }
  };

  const handleSpouseOther = () => {
    if (state.spouse_plan_type === 'Other') {
      return state.spouse_other_plan_type;
    } else {
      return state.spouse_plan_type;
    }
  };

  const validDate = (year, month) => {
    if (month === '' || year === '') {
      return null;
    } else {
      return `${year}-${month}-1`;
    }
  };

  const updateSalesforceUser = () => {
    setLoading(true);

    const data = {
      leadID: state.lead_id,
      medicarePartBDateCurrent: validDate(
        state.year_enrolled,
        state.month_enrolled
      ),
      currentCoverage:
        state.covered_by_other === 'yes' ? state.other_insurance_type : null,
      currentPlanType:
        state.covered_by_other === 'yes' ? handlePrimaryOther() : null,
      currentProvider:
        state.covered_by_other === 'yes' ? state.insurance_provider : null,
      supplementalFormReceived: true,
      spouseMedicarePartBDateCurrent:
        hasSpouse === 'yes'
          ? validDate(state.spouse_year_enrolled, state.spouse_month_enrolled)
          : null,
      spouseCurrentCoverage:
        hasSpouse === 'no'
          ? null
          : state.spouse_covered_by_other === 'yes'
          ? state.spouse_other_insurance_type
          : null,
      spouseCurrentProvider:
        hasSpouse === 'no'
          ? null
          : state.spouse_covered_by_other === 'yes'
          ? state.spouse_insurance_provider
          : null,
      spouseCurrentPlanType:
        hasSpouse === 'no'
          ? null
          : state.spouse_covered_by_other === 'yes'
          ? handleSpouseOther()
          : null,
      otherPhone: parseInt(FormUtils.cleansePhone(state.additional_phone)),
      street: state.street,
      initialNotes: state.additional_info,
    };
    FormService.updateSalesforceLead(data).then(() => {
      const dbData = {
        leadID: state.lead_id,
        enrolledMedicarePartB: state.enrolled_medicare_part_b,
        monthEnrolled: state.month_enrolled,
        yearEnrolled: state.year_enrolled,
        coveredByOther: state.covered_by_other,
        otherInsuranceType: state.other_insurance_type,
        planType: handlePrimaryOther(),
        insuranceProvider: state.insurance_provider,
        hasSpouse: hasSpouse,
        spouseEnrolledMedicarePartB: state.spouse_enrolled_medicare_part_b,
        spouseMonthEnrolled: state.spouse_month_enrolled,
        spouseYearEnrolled: state.spouse_year_enrolled,
        spouseCoveredByOther: state.spouse_covered_by_other,
        spouseOtherInsuranceType: state.spouse_other_insurance_type,
        spousePlanType: handleSpouseOther(),
        spouseInsuranceProvider: state.spouse_insurance_provider,
        mailInformation: state.mail_information,
        street: state.street,
        city: state.city,
        state: state.state,
        zip: state.zip,
        additionalphone: state.additional_phone,
        additionalInfo: state.additional_info,
      };
      FormService.updateLeadInDB(dbData)
        .then(() => {
          resetSessionData();
          window.location.href = `/thanks?info=${encodeParams()}`;
        })
        .catch((error) => {
          console.error('Something went wrong with the lead: ', error);
        });
    });
  };

  const sendEmail = () => {
    const data = {
      subject: 'Rates.MedicarePlanG - Supplemental Form Submission',
      enrolledMedicarePartB: state.enrolled_medicare_part_b,
      monthEnrolled: state.month_enrolled,
      yearEnrolled: state.year_enrolled,
      coveredByOther: state.covered_by_other,
      otherInsuranceType: state.other_insurance_type,
      planType: handlePrimaryOther(),
      insuranceProvider: state.insurance_provider,
      hasSpouse: hasSpouse,
      spouseEnrolledMedicarePartB: state.spouse_enrolled_medicare_part_b,
      spouseMonthEnrolled: state.spouse_month_enrolled,
      spouseYearEnrolled: state.spouse_year_enrolled,
      spouseCoveredByOther: state.spouse_covered_by_other,
      spouseOtherInsuranceType: state.spouse_other_insurance_type,
      spousePlanType: handleSpouseOther(),
      spouseInsuranceProvider: state.spouse_insurance_provider,
      mailInformation: state.mail_information,
      street: state.street,
      city: state.city,
      state: state.state,
      zip: state.zip,
      additionalPhone: state.additional_phone,
      additionalInfo: state.additional_info,
    };

    FormService.sendEmail(data).catch((error) => {
      console.error('Something went wrong with the email: ', error);
    });
  };

  useEffect(() => {
    if (state.selectedComponent === undefined) {
      console.log('Initializing cookies');
      resetFromSessionData();
      return;
    }

    storeSessionData();
    if (state.selectedComponent !== 'Submit') {
      return;
    }
    // Handle the data here, the state variable contains all the form
    // data, send it then change the page to /thank-you
    updateSalesforceUser();
    sendEmail();
  }, [state]);

  useEffect(() => {
    decodeParams();
  }, []);

  useEffect(() => {
    setSpouse();
    setState((prevState) => ({
      ...prevState,
      lead_id: params.lead_id,
      call_in_num: params.callinnum,
    }));
  }, [params]);

  const setSpouse = () => {
    setHasSpouse(params.spouse);
  };

  const decodeParams = () => {
    const params = new URL(window.location.href).searchParams.get('info');
    if (params) {
      let paramsObj = {};
      let decodedParams = atob(params);
      let vars = decodedParams.replace('?', '').split('&');
      vars.forEach((param) => {
        let splitParam = param.split('=');
        paramsObj[splitParam[0]] = splitParam[1];
      });
      setParams(paramsObj);
    } else {
      window.location.href = '/ms';
    }
  };

  const encodeParams = () => {
    return btoa(
      `callinnum=${state.call_in_num}&zip=${params.zip}&age=${params.age}&gender=${params.gender}&state=${params.state}`
    );
  };

  // Method to store state variable into session storage
  const storeSessionData = () => {
    window.sessionStorage.setItem('sf_state', JSON.stringify(state));
    window.sessionStorage.setItem('sf_params', JSON.stringify(params));
  };

  const resetSessionData = () => {
    window.sessionStorage.removeItem('sf_state');
    window.sessionStorage.removeItem('sf_params');
  };

  const resetFromSessionData = () => {
    window.onbeforeunload = function() {
      return 'Sure you want to leave?';
    };

    //  Remove Primary Form Session Values
    window.sessionStorage.removeItem('pf_state');
    window.sessionStorage.removeItem('pf_cookies');
    window.sessionStorage.removeItem('pf_lead_data');

    // Set values to state from session storage for secondary form
    const stateValues = window.sessionStorage.getItem('sf_state');
    const paramValues = window.sessionStorage.getItem('sf_params');

    if (stateValues) {
      setState(JSON.parse(stateValues));
    } else {
      state.selectedComponent = '';
    }
    if (paramValues) setParams(JSON.parse(paramValues));
  };

  return (
    <Layout header={!loading} footer={!loading} stickyFooter={false}>
      {renderInnerComponent()}
    </Layout>
  );
}

export default SupplementNavigation;
